const FRONTEND_URL = window.location.origin;

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENTID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AUTHORITY}.onmicrosoft.com`,

    redirectUri: `${FRONTEND_URL}/auth/microsoft`,
    postLogoutRedirectUrl: `${FRONTEND_URL}/`,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
